import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  category: {
    marginBottom: '4rem',
    minHeight: '8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
      minHeight: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '2rem',
      minHeight: '0rem',
    },
  },
  nameListItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '1rem',
    paddingLeft: '1rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  nameListItemContent: {
    listStyleType: '"-"',
    paddingLeft: '1rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '295px',
    },
  },
}));

export default useStyles;
