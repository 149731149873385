import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useBreakpoints from '@utils/useBreakpoints';
import { scroller } from 'react-scroll';
import CTAButton from '@components/common/CTAButton';
import useStyles from './styles';

const ProjectListHeadingSection = ({ text }) => {
  const classes = useStyles();
  const { smDown, mdDown } = useBreakpoints();

  const handleClick = () => {
    scroller.scrollTo('client-list-section', {
      smooth: 'easeInOutCubic',
    });
  };

  return (
    <section className={classes.root}>
      <Typography
        variant="h1"
        component="h2"
        className={classes.heading}
      >
        { text }
      </Typography>
      <CTAButton
        label="MORE"
        width={smDown ? '180px' : mdDown ? '200px' : '220px'}
        onClick={handleClick}
      />
    </section>
  );
};

ProjectListHeadingSection.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ProjectListHeadingSection;
