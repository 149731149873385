import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10rem 10rem 20rem',
    backgroundColor: '#F7F7FF',
    [theme.breakpoints.down('md')]: {
      padding: '7rem 7rem 15rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 3rem 6rem',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '3rem 2.5rem 6rem',
    },
  },
  heading: {
    marginBottom: '6rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
    },
  },
}));

export default useStyles;
