import React from 'react';
import PropTypes from 'prop-types';
import ProjectThumbnailList from '@components/project-list/ProjectThumbnailList';
import GatsbyImagePropTypes from '@prop-types/gatsby-image';
import useStyles from './styles';

const ProjectCatalogSection = ({ projects }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <ProjectThumbnailList
        xs={12}
        sm={6}
        lg={4}
        projects={projects}
      />
    </section>
  );
};

ProjectCatalogSection.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    titleShort: PropTypes.string,
    slug: PropTypes.string.isRequired,
    clientName: PropTypes.string.isRequired,
    clientNameShort: PropTypes.string,
    gradientColorLight: PropTypes.string.isRequired,
    gradientColorDark: PropTypes.string.isRequired,
    primaryImage: PropTypes.shape({
      title: PropTypes.string,
      fluid: GatsbyImagePropTypes.fluid.isRequired,
    }),
  })).isRequired,
};

export default ProjectCatalogSection;
