import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SectionTitle from '@components/common/SectionTitle';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import ClientListItem from './ClientListItem';

const ClientListSection = ({ clients }) => (
  <CenteredSectionContainer
    paddingXl="0 70px"
    paddingSm="0 30px"
    paddingXs="0 25px"
    maxWidthXl="1300px"
  >
    <SectionTitle>
      CLIENT LIST
    </SectionTitle>
    <Grid container spacing={4}>
      {clients.map((client) => (
        <ClientListItem
          key={client.id}
          title={client.title}
          nameList={client.nameList}
        />
      ))}
    </Grid>
  </CenteredSectionContainer>
);

ClientListSection.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    nameList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  })).isRequired, // eslint-disable-line
};

export default ClientListSection;
