import React from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import Wires from '@components/common/Wires';
import FirstWire from '@images/wire-project-1.png';
import { Element as ScrollElement } from 'react-scroll';
import PageHeader from '@components/common/PageHeader';
import ProjectListHeadingSection from '@components/project-list/ProjectListHeadingSection';
import ProjectCatalogSection from '@components/project-list/ProjectCatalogSection';
import ClientListSection from '@components/project-list/ClientListSection';
import SectionSpacer from '@components/common/SectionSpacer';
import FeaturedProjectsSection from '@components/common/FeaturedProjectsSection';
import ContactBannerSection from '@components/common/ContactBannerSection';

const ProjectsPage = ({ data }) => {
  const projectsPage = data.projectsPage.edges[0].node;

  return (
    <Layout>
      <SEO title="Projects" />
      <Wires
        wires={[
          {
            src: FirstWire,
            side: 'right',
            bottom: 25,
          },
        ]}
      />
      <PageHeader
        pageTitle="PROJECTS"
        image={data.headerImage.childImageSharp.fluid}
      />
      <ProjectListHeadingSection
        text={projectsPage.lead.lead}
      />
      <ProjectCatalogSection
        projects={projectsPage.projectList}
      />
      <SectionSpacer />
      <ScrollElement name="client-list-section">
        <ClientListSection
          clients={projectsPage.clientList}
        />
      </ScrollElement>
      <SectionSpacer />
      <FeaturedProjectsSection />
      <ContactBannerSection />
    </Layout>
  );
};

ProjectsPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
};

export default ProjectsPage;

export const pageQuery = graphql`
  query {
    projectsPage: allContentfulProjectsPage(limit: 1) {
      edges {
        node {
          id
          lead {
            lead
          }
          projectList {
            id
            title
            titleShort
            slug
            clientName
            clientNameShort
            gradientColorLight
            gradientColorDark
            primaryImage {
              title
              fluid(maxWidth: 640) {
                ...GatsbyContentfulFluid_noBase64
              }
            }
          }
          clientList {
            id
            title
            nameList
          }
        }
      }
    }
    headerImage: file(relativePath: {eq: "page-header-projects.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
