import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '-100px',
    paddingLeft: '100px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-70px',
      paddingLeft: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '0',
      paddingLeft: '0',
    },
  },
}));

export default useStyles;
