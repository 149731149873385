import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './styles';

const ClientListItem = ({ title, nameList }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Typography
        variant="h4"
        className={classes.category}
      >
        {title}
      </Typography>
      <ul className={classes.nameList}>
        {nameList.map((name) => (
          <div
            className={classes.nameListItem}
            key={name}
          >
            <Typography
              variant="body2"
              component="li"
              className={classes.nameListItemContent}
            >
              {name}
            </Typography>
          </div>
        ))}
      </ul>
    </Grid>
  );
};

ClientListItem.propTypes = {
  title: PropTypes.string.isRequired,
  nameList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ClientListItem;
